<template>
  <v-col
    :class="borderClasses"
    class="cw-business-loan-terms-table cw-border-container"
    cols="12"
  >
    <h3
      class="
        cw-business-loan-terms-table__title
        text-body-2
        font-weight-bold
        mb-2
      "
    >
      {{ title }}
    </h3>

    <div class="cw-application__table">
      <table>
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="`item-${i}`"
          >
            <td
              v-for="(column, c) in columns"
              :key="`column-${c}`"
              :class="columnClass(c)"
            >
              {{ item[column] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'CwBusinessLoanTermsTable',

  props: {
    borderColor: {
      type: String,
      default: '',
    },
    center: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    borderClasses() {
      return {
        [`cw-border-container--${this.borderColor}`]: this.borderColor !== '',
        [`cw-border-container--${this.center}-center`]: this.center !== '',
      };
    },
  },

  methods: {
    columnClass(index) {
      return { 'font-weight-medium text-right': (index + 1) % 2 === 0 };
    },
  },
};
</script>
